<template>
  <div>
    <div class="loader" v-if="!employee">
      <v-progress-circular color="red" indeterminate></v-progress-circular>
    </div>
    <div v-if="employee" class="view-conitaner">
      <div class="action-bar">
        <v-btn @click="addToMyTeam">Add to my team</v-btn>
        <v-btn>Potential</v-btn>
        <v-btn>Vetting</v-btn>
        <v-btn>Contact (HR)</v-btn>
        <v-btn @click="download">Detailed CV</v-btn>
        <v-btn @click="$emit('close')" icon="mdi-close"></v-btn>
      </div>
      <div class="main-template-container" id="element-to-convert">
        <div class="left-section">
          <img v-if="employee.PhotoUrl" :src="employee.PhotoUrl" class="employee-image" />
          <img v-else src="@/assets/images/default-avatar.avif" class="employee-image" />
          <div class="title-bar mt-4">About</div>
          <div class="employee-details about">
            <span v-if="employee.CodeClanCode">RefNo: {{ employee.CodeClanCode }}</span>
            <span v-if="employee.Educations && employee.Educations.length > 0" class="mt-4">Education:</span>
            <span class="mt-2" v-for="(item, index) in getEducations(employee.Educations)" :key="index">
              {{ item }}
            </span>
            <span class="mt-4" v-if="employee.BirthDate">Age: {{ getAge(employee.BirthDate) }}</span>
            <span class="mt-4 description">{{ employee.Description }}</span>
          </div>
          <!-- <img
            crossorigin="anonymous"
            src="@/assets/images/code-clan-logo.svg"
            class="logo"
          /> -->
          <div class="logo"></div>
        </div>
        <div class="right-section">
          <div class="employee-name">
            {{ capitalize(employee.FirstName) }}
            {{ capitalize(employee.LastName) }}
            <v-dialog v-model="introductionDialogVisible" max-width="800">
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn
                  v-tooltip="'Introduce ' + capitalize(employee.FirstName) + ' ' + capitalize(employee.LastName)"
                  variant="text"
                  class="mb-8"
                  v-bind="activatorProps"
                  icon="mdi-auto-fix">
                  <v-icon></v-icon>
                </v-btn>
              </template>

              <template v-slot:default>
                <v-card :loading="tmRAGloading" :title="'About ' + capitalize(employee.FirstName) + ' ' + capitalize(employee.LastName)">
                  <v-card-text v-if="introductionDialogVisible">
                    <div v-html="introduction"></div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text="Close Dialog" class="secondary_btn" @click="introductionDialogVisible = false"></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </div>
          <div class="employee-title">
            {{ getTitle(employee.ProfessionalDetail?.JobTitle?.Id) }}
          </div>
          <div class="title-bar">Skills</div>
          <div class="employee-details skills">
            {{ getSkills(employee.Skills) }}
          </div>
          <div class="title-bar">Experiences</div>
          <div class="employee-details experiences">
            {{ employee.CareerHistoryDescription }}
          </div>
          <div class="title-bar">Personality</div>
          <div class="employee-details personality">
            {{ employee.ProfessionalDetail?.Personality ?? "-" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import CareerHistories from "shared-components/src/models/CareerHistories"
  import CoreSkill from "shared-components/src/models/CoreSkill"
  import Details from "shared-components/src/models/Details"
  import {
    ClientGetTeammemberEducationModel,
    ClientGetTeammemberResponseModel,
    ClientTeammemberApi,
    ClientGetTeammemberSkillModel,
    OpenAIApi,
    TeammemberInfoRAGRequestModel,
    PositionDetailResponseModel,
  } from "shared-components/src/services/openApi/api"
  import { defineComponent, toRaw } from "vue"

  export default defineComponent({
    inject: ['positionDetails'],
    props: {
      employeeId: {
        type: String,
        required: true,
      },
      skills: {
        type: Array as () => CoreSkill[],
        required: true,
      },
      details: {
        type: Array as () => Details[],
        required: true,
      },
    },
    async mounted() {
      this.employee = (await new ClientTeammemberApi().clientGetTeammember(this.employeeId)).data
    },
    components: {},
    data() {
      return {
        position: this.positionDetails as PositionDetailResponseModel,
        employee: null as ClientGetTeammemberResponseModel | null,
        tmRAGloading: false,
        introductionDialogVisible: false,
        introduction: "",
      }
    },
    methods: {
      addToMyTeam() {
        this.$emit("AddToMyTeam", this.employee?.Id)
      },
      sanitizeHtml(htmlString: string) {
        // Create a temporary DOM element
        const tempDiv = document.createElement("div")

        // Set the HTML content
        tempDiv.innerHTML = htmlString

        // List of unwanted tags
        const unwantedTags = ["html", "head", "body", "script", "iframe", "style", "link", "meta", "object", "embed"]

        // Remove unwanted elements
        unwantedTags.forEach((tag) => {
          const elements = tempDiv.getElementsByTagName(tag)
          // Convert HTMLCollection to an array to safely remove elements while iterating
          const elementsArray = Array.from(elements)
          elementsArray.forEach((element) => {
            element.parentNode?.removeChild(element)
          })
        })

        // Return the sanitized HTML as a string
        return tempDiv.innerHTML
      },
      async introduce() {
        this.tmRAGloading = true
        this.position.JobRole = this.details.find((item) => item.id == this.position?.JobRole)?.Name ?? undefined
        var request = {
          name: this.employee?.FirstName,
          age: this.getAge(this.employee?.BirthDate),
          skills: this.getSkills(this.employee?.Skills),
          biography: this.employee?.Description,
          personality: this.employee?.ProfessionalDetail?.Personality,
          experience: this.employee?.CareerHistoryDescription,
          education: this.getEducations(this.employee?.Educations).join(", "),
          positionDetails: this.position,
        } as TeammemberInfoRAGRequestModel;
        try {
          if (this.introduction == "") {
            var content = (await new OpenAIApi().teammemberInfoRAG(request)).data
            this.introduction = this.sanitizeHtml(content)
          }
        } finally {
          this.tmRAGloading = false
        }
      },
      async download() {
        const html2canvas = require("html2canvas")
        const { jsPDF } = require("jspdf")
        html2canvas(document.querySelector("#element-to-convert"), {
          quality: 3,
          dpi: 600,
          scale: 2,
        }).then((canvas: any) => {
          var img = canvas.toDataURL("image/png")
          var pdf = new jsPDF("p", "pt", "a4")
          pdf.addImage(img, "png", 0, 0, 595, 842)
          pdf.save("CV.pdf")
        })
      },
      capitalize(name: string | undefined) {
        if (name) return name.charAt(0).toUpperCase() + name.slice(1)
        return name
      },
      getTitle(id: string | undefined) {
        if (id) {
          return this.capitalize(this.details.find((item) => item.id == id)?.Name ?? undefined)
        }
        return "-"
      },
      getAge(birthDate: string | undefined) {
        if (birthDate) {
          const currentDate = new Date()
          const difference = currentDate.valueOf() - new Date(birthDate).valueOf()
          const age = Math.floor(difference / 31557600000)
          return age
        }
        return "-"
      },
      getEducations(educations: ClientGetTeammemberEducationModel[] | undefined) {
        if (educations) {
          const stringEducations: string[] = []
          educations.forEach((education) => {
            const educationField = this.capitalize(`${education.Degree} of ${education.StudyField}`)
            if (educationField) stringEducations.push(educationField)
          })
          return stringEducations
        }
        return []
      },
      getSkills(skills: ClientGetTeammemberSkillModel[] | undefined) {
        let skillList = toRaw(skills)
        if (skillList && skillList.length > 0) {
          skillList = skillList.sort((a, b) => {
            if (a.IsTopSkill == true) {
              return -1
            }
            if (a.IsTopSkill == false) {
              return 1
            }
            return 0
          })
          const stringSkills: string[] = []
          skillList.forEach((element) => {
            const skillName = this.capitalize(this.skills.find((item) => item.id == element.SkillId)?.Text ?? undefined)
            if (skillName) stringSkills.push(skillName)
          })
          return stringSkills.join(", ")
        }
        return "-"
      },
      getCareerHistories(careerHistories: CareerHistories[] | null) {
        if (careerHistories) {
          const stringCareer: string[] = []
          careerHistories.forEach((element) => {
            stringCareer.push(element.Description)
          })
          return stringCareer.join(", ")
        }
        return ""
      },
    },
    watch: {
      introductionDialogVisible(newVal) {
        if (newVal) {
          this.introduce()
        }
      },
    },
  })
</script>
<style scoped lang="scss">
  .loader {
    height: 100vh;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .view-conitaner {
    position: relative;
    max-width: 800px;
    background: #efefef;
    .action-bar {
      position: fixed;
      display: flex;
      gap: 5px;
      background-color: #dbdbdb;
      padding: 10px;
      width: 100%;
      max-width: 800px;
      z-index: 999999;
      justify-content: center;
    }
    .download-btn {
      position: absolute;
      top: 20px;
      right: -20px;
    }

    .main-template-container {
      display: flex;
      gap: 25px;
      padding: 65px 35px 0 35px;

      .title-bar {
        background-color: #ef0700;
        color: white;
        font-size: 35px;
        font-weight: 600;
      }
      .employee-details {
        font-size: 18px;
        font-weight: 600;
      }
      .left-section {
        max-width: 367px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #dbdbdb;
        padding: 23px 15px 20px 15px;
        border-top-left-radius: 190px;
        border-top-right-radius: 190px;

        .employee-image {
          border-radius: 190px;
          height: 320px;
          margin: 0 auto;
        }

        .description {
          overflow-wrap: break-word;
        }

        .title-bar {
          margin: 0 -30px 0 -50px;
          padding: 2px 50px;
          position: relative;
          &::after {
            content: "";
            width: 0;
            height: 0;
            border-left: 15px solid #000;
            border-bottom: 15px solid transparent;
            position: absolute;
            right: 0;
            bottom: -15px;
          }
        }
        .logo {
          width: 250px;
          height: 90px;
          background-size: contain;
          background-image: url("../../assets/images/CODE-CLAN-Red.png");
        }
        .about {
          flex: 2;
          display: flex;
          flex-direction: column;
        }
      }
      .right-section {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;
        padding-top: 50px;
        width: 355px;
        .skills {
          min-height: 400px;
        }
        .experiences {
          min-height: 150px;
          overflow-wrap: break-word;
        }
        .personality {
          min-height: 150px;
          overflow-wrap: break-word;
        }
        .employee-name {
          font-size: 50px;
          font-weight: 600;
          text-align: center;
        }
        .employee-title {
          font-size: 25px;
          text-align: center;
        }
        .title-bar {
          margin: 0 -35px 0 -25px;
          padding: 2px 25px;
        }
      }
    }
  }
</style>
